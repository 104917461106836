import React from 'react';
import './jobs.scss'

export const jobListings5 = [
  {
    jobTitle: 'Assistant Program Coordinator',
    jobLocation: 'Remote',
    jobType: 'Contractor- Full-Time ',
    aboutUs: 'Hamoye Foundation is dedicated to empowering the next generation of leaders in data science and sustainability. We are seeking an Assistant Program Coordinator to join our Hamoye Internships team. If you are passionate about providing valuable learning experiences to young talents and are excited about fostering their growth, we want to hear from you.',
    jobDescription : 'As an Assistant Program Coordinator, you will play a pivotal role in supporting the Hamoye Internships program. You will ensure timely and accurate communication with interns, timely delivery of internship courses, and partner organizations to ensure the successful execution of internship initiatives. Your contribution will be vital in shaping the careers of emerging data science professionals.    ',

     jobResponsibilities:[
      'Assist in the planning, coordination, and execution of the Hamoye Internships program.',
      'Collaborate with program coordinators to onboard interns and partner organizations.',
      'Facilitate communication between interns, mentors, and program coordinators.',
      'Support interns in setting and achieving their learning objectives and goals.',
      `Assist in tracking and evaluating intern performance and project outcomes.`,
      'Maintain program records, databases, and documentation.',
      'Coordinate meetings, workshops, and networking events for interns.',
      'Provide administrative support to ensure the smooth operation of the program.',
    ],
    jobRequirements:[
      `Bachelor's degree in Education, Business Administration, or a related field.`,
      'Strong organizational and multitasking skills.',
      'Excellent interpersonal and communication skills.',
      'Excellent written and verbal communication skills.',
      'Detail-oriented with a commitment to accuracy.',
      'Experience in program coordination or internship facilitation is a plus.',
      'Passion for mentoring and guiding emerging talents.',
      'Enthusiasm for data science, sustainability, and education.',
      'Ability to work collaboratively in a dynamic and fast-paced environment.'
    ],
    benefits:[
      'Competitive salary.',
      'Opportunities for professional growth and development.',
      'Collaborative and inclusive work environment.',
      'Meaningful and impactful projects.',
      'Contribution to global challenges and solutions.',
      'Flexibility and work-life balance.',
    ], 
    footer:[
    'Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.\
    If you are ready to apply your data science skills to make a positive impact on the world,\
     we invite you to be part of our team.',
    'To apply, please complete this application form and upload your resume, ',
    ]
}
]

function AProgramcord ({ jobTitle, jobLocation, jobType, aboutUs, jobResponsibilities, jobDescription, jobRequirements,  benefits, footer }) {
  return (
    <div className='jobss'>
      <h2 className='title_head'><b>Job Title: {jobTitle}</b></h2>
      <p><strong>Job Location:</strong> {jobLocation}</p>
      <p><strong>Job Type:</strong> {jobType}</p>
      <div className='job_info'>
      <div>
      <p><strong>About Us:</strong></p>
      <p>{aboutUs}</p>
      </div>
      <div>
      <h3><b>Job Description:</b></h3>
      <p>{jobDescription}</p>
      </div>
      <div>
      <h3><b>Job Responsibilities:</b></h3>
      {jobResponsibilities && jobResponsibilities.length > 0 ? (
        <ul>
          {jobResponsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      ) : (
        <p>No job responsibilities listed.</p>
      )}
      </div>
      <div>
      <h3><b>Job Requirements:</b></h3>
      {jobRequirements && jobRequirements.length > 0 ? (
        <ul>
          {jobRequirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      ) : (
        <p>No job requirements listed.</p>
      )}
      </div>
      <div>
      <h3><b>Benefits:</b></h3>
      {benefits && benefits.length > 0 ? (
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      ) : (
        <p>No benefits listed.</p>
      )}
      </div>
      <div>
      {/*{footer && footer.length > 0 ? (
        <ul>
        {footer.map((foot, index) => (
          <li key={index}>{foot}</li>
        ))}
        <a href="https://forms.gle/3Yi12MxP3tw4pGL86'">here</a>
        </ul>
      ):(
        <p>no footer</p>
      )}*/}
      <div>
      Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.
    If you are ready to apply your data science skills to make a positive impact on the world,
     we invite you to be part of our team.
      </div>
      <div className='fooz'>
      To apply, please complete this application form and upload your resume, <a href="https://forms.gle/3Yi12MxP3tw4pGL86"><b>here.</b></a>
      </div>
      <div>Hamoye Foundation is an equal opportunity employer. 
    We celebrate diversity and are committed to creating an inclusive environment for all employees.
    </div>
      </div>
      {/*<button>Apply Now</button>*/}
      </div>
    </div>
  );
}

export default AProgramcord ;