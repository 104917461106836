import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppProvider from "./context/AppContext";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Toaster } from "react-hot-toast"; 
import { ContactProvider } from './context/ContactContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

<GoogleReCaptchaProvider
reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
container={{
  element: "newcap",
  parameters: {
    badge: "inline",
    theme: "dark",
  }, 
}}
> 
  <React.StrictMode>
  <ContactProvider>
    <App />
    </ContactProvider>
    <Toaster position="top-right" />
  </React.StrictMode>
</GoogleReCaptchaProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{/*<GoogleReCaptchaProvider
reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY}
container={{
  element: "recap",
  parameters: {
    badge: "inline",
    theme: "dark",
  },  </AppProvider>
}}
> </GoogleReCaptchaProvider>*/}