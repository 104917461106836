export const ContactConfig = {
    heading:
      "Hello! Partner with Hamoye Foundation today. ",
    img: "/images/contact.png",
    fields: [
      {
        label: "First Name*",
        name: "fName",
        type: "text",
        element: "input",
        placeholder: "",
      },
      {
        label: "Last Name*",
        type: "text",
        name: "lName",
        element: "input",
        placeholder: "",
      },
      {
        label: "Email Address*",
        type: "email",
        name: "email",
        element: "input",
        placeholder: "",
      },
      {
        label: "Phone No (+01 234-567-8910)",
        type: "number",
        name: "phoneNumber",
        element: "input",
        placeholder: "",
      },
      {
        label: "Job Title",
        type: "text",
        name: "title",
        element: "input",
        placeholder: "",
      },
      {
        label: "Institution/Company",
        type: "text",
        name: "company",
        element: "input",
        placeholder: "",
      },
      {
        label: "Country*",
        type: "text",
        name: "country",
        element: "select",
        placeholder: "",
      },
      {
        label: "How can we help? *",
        type: "text",
        name: "description",
        element: "textarea",
        placeholder: "",
      },
    ],
    footer:
      "Please see our Privacy Policy regarding how we will handle this information.",
  };
  