import * as yup from "yup";

export const schema = yup
  .object({
    fName: yup
      .string()
      .trim()
      .min(2, "name should be at least 2 letters long")
      .required(),
    lName: yup
      .string()
      .trim()
      .min(2, "name should be at least 2 letters long")
      .required(),
    email: yup.string().email("please use a valid email").required(),
    phoneNumber: yup
      .string()
      .matches(
        /^\+?([0-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,12})$/,
        {
          message: "use a valid phone number",
          excludeEmptyString: true,
        }
      ),
    title: yup.string().trim(),
    company: yup.string("enter a valid company name").trim(),
    country: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .required(),
    description: yup
      .string()
      .trim()
      .min(
        60,
        "In order to reduce spam, please provide more details on how we can be of assistance.  We're looking forward to connecting with you"
      )
      .required(),
  })
  .required();
