// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/malaria_new.png'
import aelectrify from '../../assets/images/mmalaria.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Remi() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Remittance Patterns</div>
           <div className='electric_speech'>
           For many developing nations, remittance from their  diaspora communities 
           represent a major lifeline for families, 
           contributing to poverty reduction, economic stability,
            education and healthcare support.
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif' />
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Cash with Compassion: Unpacking Remittances in Developing Economies</div>
           <div className='electric_speech'>
           Research on remittances and their impact on developing economies holds immense importance for both policymakers and economists. Remittances are a significant source of income for many households in developing countries. Understanding the patterns, trends, and drivers of remittances is crucial for designing effective policies and promoting investments that can help enhance their positive impact on local economic development.
         For many nations in developing economies, inflows of remittances from diaspora communities are large enough to influence exchange rates, inflation, and overall economic stability. It can have both positive and negative impacts on the local labor force, affecting productivity and employment patterns. It is important to understand trends and patterns of key factors such as the channels through which remittances are sent, the cost of remittance transfers, 
             the financial infrastructure supporting these transfers, and the potential for remittance-funded investments in education, entrepreneurship, and small businesses. 
           </div>
        </article>
       </section>

       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>
           AI-driven research can help nations understand and optimize remittances as an asset.
            We are interested in predicting trends and patterns in remittance flows and impact on local economies, 
            including relevant driving factors such as the growth of fintech, government policies and diaspora engagement. 

           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>

   

  
      </main>
     <Footer/>
    </div>
  );
}

export default Remi;
