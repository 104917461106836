import { Fragment, useMemo, useState } from "react";
import countryList from "react-select-country-list";
import { Combobox, Transition } from "@headlessui/react";
import twin from 'twin.macro';
import styles from "./Contact.module.scss";
import  "./Contact.module.scss";
const SelectCountry = ({ name, placeholder, field }) => {
  const countries = useMemo(() => countryList().getData(), []);
  const [query, setQuery] = useState("");

  const filteredCountries =
    query === ""
      ? countries
      : countries.filter((country) =>
          country.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div >
    <Combobox as={"div"} {...field} items={countries}>
      <div className="relative">
        <Combobox.Input
          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
          displayValue={(field) => field?.label || "United States"}
          onChange={(event) => setQuery(event.target.value)}
        />

        <Transition
        className={styles.overal}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
        {/*absolute bg-red z-[1] max-w-[350px] -mt-3 h-60 w-full overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none*/}
          <Combobox.Options className={styles.orpen}>
            {filteredCountries.length === 0 && query !== "" ? (
              <div className="bg-white z-[1] relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredCountries.map((country) => (
                <Combobox.Option
                  key={country?.value}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-black text-red cursor-pointer" : "text-gray-900"
                    }`
                  }
                  value={country}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {country.label}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-teal-600"
                          }`}
                        >
                          {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
    </div>
  );
};

export default SelectCountry;
