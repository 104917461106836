import React from 'react';
import './jobs.scss'

export const jobListings4 = [
  {
    jobTitle: 'Junior Data Storyteller',
    jobLocation: 'Remote',
    jobType: 'Contractor- Full-Time or Part-Time hours',
    aboutUs: 'At Hamoye Foundation, we believe in the power of data to drive positive change. We are seeking a Data Storyteller to join our team and help us convey the compelling narratives hidden within our data to inform, inspire, and engage our audiences. If you are passionate about translating complex data into meaningful stories, we want to hear from you.',
    jobDescription : 'As a Data Storyteller at Hamoye Foundation, you will play a pivotal role in conveying the impact of our projects, from addressing infrastructure deficits to tackling climate change and enhancing education. You will collaborate with cross-functional teams to craft data-driven narratives that resonate with diverse audiences, including policymakers, researchers, and the public.',

     jobResponsibilities:[
      'Collaborate with data scientists, researchers, and subject matter experts to understand and interpret complex datasets.',
      'Transform data into compelling stories and visuals that effectively communicate insights and findings.',
      'Create data-driven reports, infographics, and interactive visualizations to engage diverse audiences.',
      'Develop written and visual content for presentations, reports, publications, and online platforms.',
      `Collaborate with communications teams to ensure data-driven narratives align with our organization's goals and mission.`,
      'Stay updated on data visualization best practices and emerging storytelling techniques.',
      'Contribute to enhancing data literacy and storytelling skills within the organization.',
    ],
    jobRequirements:[
      `Bachelor's degree in Data Science, Statistics, Journalism, Communications, or a related field.`,
      'Proven experience in data storytelling, data journalism, or a similar role.',
      'Strong proficiency in data visualization tools such as Tableau, Power BI, or D3.js..',
      'Excellent written and verbal communication skills.',
      'Ability to distill complex data into clear and engaging narratives.',
      'Proficiency in Adobe Creative Suite (Illustrator, Photoshop) is a plus.',
      'Familiarity with coding languages (e.g., Python, R) is a bonus.',
      'Passion for making data-driven insights accessible to a wide audience.',
      'Ability to work collaboratively in a cross-functional team environment.'
    ],
    benefits:[
      'Competitive salary.',
      'Opportunities for professional growth and development.',
      'Collaborative and inclusive work environment.',
      'Meaningful and impactful projects.',
      'Contribution to global challenges and solutions.',
      'Flexibility and work-life balance.',
    ], 
    footer:[
    'Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.\
    If you are ready to apply your data science skills to make a positive impact on the world,\
     we invite you to be part of our team.',
    'To apply, please complete this application form and upload your resume, ',
    ]
}
]

function JDatastory ({ jobTitle, jobLocation, jobType, aboutUs, jobResponsibilities, jobDescription, jobRequirements,  benefits, footer }) {
  return (
    <div className='jobss'>
      <h2 className='title_head'><b>Job Title: {jobTitle}</b></h2>
      <p><strong>Job Location:</strong> {jobLocation}</p>
      <p><strong>Job Type:</strong> {jobType}</p>
      <div className='job_info'>
      <div>
      <p><strong>About Us:</strong></p>
      <p>{aboutUs}</p>
      </div>
      <div>
      <h3><b>Job Description:</b></h3>
      <p>{jobDescription}</p>
      </div>
      <div>
      <h3><b>Job Responsibilities:</b></h3>
      {jobResponsibilities && jobResponsibilities.length > 0 ? (
        <ul>
          {jobResponsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      ) : (
        <p>No job responsibilities listed.</p>
      )}
      </div>
      <div>
      <h3><b>Job Requirements:</b></h3>
      {jobRequirements && jobRequirements.length > 0 ? (
        <ul>
          {jobRequirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      ) : (
        <p>No job requirements listed.</p>
      )}
      </div>
      <div>
      <h3><b>Benefits:</b></h3>
      {benefits && benefits.length > 0 ? (
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      ) : (
        <p>No benefits listed.</p>
      )}
      </div>
      <div>
      {/*{footer && footer.length > 0 ? (
        <ul>
        {footer.map((foot, index) => (
          <li key={index}>{foot}</li>
        ))}
        <a href="https://forms.gle/3Yi12MxP3tw4pGL86'">here</a>
        </ul>
      ):(
        <p>no footer</p>
      )}*/}
      <div>
      Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.
    If you are ready to apply your data science skills to make a positive impact on the world,
     we invite you to be part of our team.
      </div>
      <div className='fooz'>
      To apply, please complete this application form and upload your resume, <a href="https://forms.gle/3Yi12MxP3tw4pGL86"><b>here.</b></a>
      </div>
      <div>Hamoye Foundation is an equal opportunity employer. 
    We celebrate diversity and are committed to creating an inclusive environment for all employees.
    </div>
      </div>
      {/*<button>Apply Now</button>*/}
      </div>
    </div>
  );
}

export default JDatastory;