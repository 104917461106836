// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/foodmain2.png'
import aelectrify from '../../assets/images/foodmain.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Foodsecurity() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Food Security and Access</div>
           <div className='electric_speech'>
           Hamoye is interested in using AI to study the availability, accessibility, and affordability of nutritious food in different regions with the hope that we can robustly predict the effectiveness of
            strategies to improve food security for vulnerable populations across the continent.
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif'/>
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Combating Hunger</div>
           <div className='electric_speech'>
          Food security and access to good nutrition remains a paramount concern in Africa, where a rapidly growing population, coupled with environmental challenges and economic disparities, underscores the urgency for innovative solutions. In Africa, nearly 58 million children under the age of five are stunted, indicating chronic malnutrition. In Africa, post-harvest losses for important crops can be as high as 50% while farmer access to transportation infrastructure and distribution can be extremely poor.
          </div>
        </article>
       </section>

       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>

           We are working on data, models and tools to:
           <ul class="bullet-list">
           <li>Predict food price trends</li> 
           <li>Establish patterns of nutritional outcomes</li> 
           <li>Develop early warning systems for food crisis</li> 
           <li>Design and implement adaptation measures</li> 
        </ul>
           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>

      </main>
     <Footer/>
    </div>
  );
}

export default Foodsecurity;
