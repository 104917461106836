import { Controller } from "react-hook-form";
import SelectCountry from "./SelectCountry";
import './Contact.module.scss'
const CustomInput = ({
  label,
  name,
  placeholder,
  multiline,
  select,
  styles,
  register,
  control,
  errors,
}) => {
  //

  return (

    <div className={styles[name]}>
      <label htmlFor={name}>
        {label}
        {errors[name] ? (
          <span
            className={`${styles.errorMessage} ${
              name === "description" ? styles.desc : ""
            }`}
          >
            {errors[name]?.message || errors.country.value.message}
          </span>
        ) : null}
      </label>

      {multiline ? (
        <textarea {...register(name)} placeholder={placeholder} />
      ) : select ? (
        <>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <SelectCountry field={field} placeholder={placeholder}  />
            )}
          />
        </>
      ) : (
        <input {...register(name)} placeholder={placeholder} />
      )}
    </div>
   
  );
};

export default CustomInput;
