import "twin.macro";
import { useRef, useCallback, useState, memo, useEffect } from "react";
import { Modal } from "flowbite-react";
import { RiCloseFill } from "react-icons/ri";
import { useOnClickOutside } from "usehooks-ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { APP_ACTIONS, useAppContext } from "../../context/AppContext";
import recaptccha from '../../assets/images/recaptcha.png'
import styles from "./Contact.module.scss";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import toast from "react-hot-toast";
import { schema } from "./validationSchema";
import CustomInput from "./CustomInput";
import { ContactConfig } from "./contactConfig";
//import Link from "next/link";
import { Link } from 'react-scroll';
import { paths } from "../../configs/paths";
import './Contact.module.scss'
import twin from 'twin.macro';// eslint-disable-line

/*const ContactModal = ({ children, openContact, closeModal }) => {
  return (
    <div className="max-w-[80%]">
      <Modal
        size={700}
        dismissible={true}
        show={openContact}
        onClose={closeModal}
      >
        {children}
      </Modal>
    </div>
  );
};
const ContactMemo = memo(ContactModal);*/

const Contact = ({ onClose , satus}) => {
  //const { state, dispatch } = useAppContext();
  //const { state, dispatch } = useState();
  //const { openContact } = state || {};
  const modalRef = useRef(null);
  const [sendingMail, setSendingMail] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("recaptcha not available");
      return;
    }

    return await executeRecaptcha("contact_form");
  }, [executeRecaptcha]);

  //const closeModal = () => dispatch({ type: APP_ACTIONS.CLOSE_CONTACT });
  //const closeModal = () => dispatch();
  //useOnClickOutside(modalRef, closeModal);

  const onSubmit = async (data) => {
    setSendingMail(true);
    const loadingToast = toast.loading("submitting form...");
    try { 
      const token = await handleRecaptchaVerify();
      // Remove Captcha verification code

       if (token) {
      const response = await fetch(`https://hamoyeorg.netlify.app/.netlify/functions/contact-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
      
        },
        body: JSON.stringify({ ...data, token }),
      });
  
      if (response.ok) {
        toast.success("Message sent successfully");
        reset();
        setTimeout(() => {
          //closeModal();
        }, 3000);
      } else {
        const { message } = await response.json();
        toast.error(message);
      }
    } 
  else toast.error("Captcha error, please try again");
    }
  catch (error) {
      toast.error("Something went wrong, please try again later");
    } finally {
      toast.dismiss(loadingToast);
      setSendingMail(false);
    }
  };

  //let status =  "close";

  useEffect(() => {
    handleRecaptchaVerify();
  }, [handleRecaptchaVerify]);

  return (
    // <ContactMemo {...{ openContact, closeModal }}>
   
    <div className={styles.modalBg + " " + styles[satus]}>
      <div ref={modalRef} className={styles.contact + " " + styles[satus]}>
        <div
          ref={modalRef}
          className={styles.contactgrid}
        >
    
          <div  className={styles.imagedjust}>
          <div >{/* className="lg:(flex justify-center flex-col) relative w-full min-w-[400px] max-w-[680px] h-[782px]"*/}
            <img src={ContactConfig.img} className="object-contain" alt="" />
            
          </div>
          </div>


          <div className={styles.form_contact}>
            <div className={styles.con}>
          <RiCloseFill
               onClick={onClose}
                size={50}
                className={styles.closeIcon}
               
              />
              </div>
          <div
            className={styles.formHeading}   
            >{/*className="font-avenirMed text-[1.5rem]">*/}
              <div className={styles.set}>{ContactConfig.heading}</div>
       
            </div>
            

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)(e);
            }}
            className={styles.form}
          >
        

            <div className={styles.inputs}>
              {ContactConfig.fields.map((field, i) => (
                <CustomInput
                  label={field.label}
                  key={i}
                  name={field.name}
                  register={register}
                  control={control}
                  errors={errors}
                  placeholder={field.placeholder}
                  multiline={field.element === "textarea" ? true : false}
                  select={field.element === "select" ? true : false}
                  styles={styles}
                />
              ))}

             {/* <div className={styles.greCaptcha} id="recap" />*/}
             
            </div>

            <div className={styles.subrecap}>
             
            <div>
            <div id="newcap"></div>
                  {/*<img src={recaptccha} className={styles.captcha} alt="" />*/}
                  </div>
            <div>
              <input
                className={styles.button}
                type="submit"
                disabled={Object.keys(errors).length !== 0 || sendingMail}
              />
              </div>
            
                  </div>

            <p className={styles.footer}>
              Please see our{" "}
              {/*<Link onClick={closeModal} href={paths.privacy}  >*/}
              <a href="/privacy"  className="font-bold">
                Privacy Policy
                </a>
              {/*</Link>*/}{" "}
              regarding how we will handle this information.
            </p>
          </form>
          </div>
        </div>
      </div>
    </div>

    // </ContactMemo>
  );
};

export default Contact;
