import React from 'react';
import './jobs.scss'

export const jobListings2 = [
  {
    jobTitle: 'Junior Data Engineer',
    jobLocation: 'Remote',
    jobType: 'Contractor- Full-Time or Part-Time hours',
    aboutUs: `At Hamoye Foundation, we're dedicated to harnessing the\
    power of data for positive change across various sectors, including\
     education, healthcare, infrastructure, and environmental sustainability.\
      We are currently seeking a Junior Data Engineer to join our innovative team \
      and play a crucial role in establishing and maintaining the data infrastructure \
      that empowers us to make a meaningful impact on communities and ecosystems.`,
    jobDescription : `As a Junior Data Engineer at Hamoye Foundation,\
     you'll collaborate closely with our data engineering and analytics
      teams to support the development and upkeep of data pipelines, databases, 
      and storage solutions. This role is perfect for individuals who are passionate 
      about data engineering, eager to learn, and committed to driving positive change 
      through technology..`,

     jobResponsibilities:[
      'Collaborate with senior data scientists to collect, clean, and preprocess data from various sources',
      'Assist in developing machine learning models and algorithms to solve complex problems.',
      'Conduct exploratory data analysis (EDA) to identify patterns and insights',
      'Participate in data visualization and reporting to communicate findings effectively.',
      'Support the implementation of data-driven solutions in diverse domains, such as healthcare, education, and environmental sustainability.',
      'Stay up-to-date with the latest trends and developments in data science and AI.',
      'Contribute to research papers and reports to share insights with the broader community.',
      'Assist in building and maintaining data pipelines and workflows.'
    ],
    jobRequirements:[
      `Bachelor's degree in Data Engineering, Computer Science, or a related field.`,
      'Proficiency in programming languages like Python, Java, or SQL.',
       'Basic understanding of data modeling and database design principles.',
       'Familiarity with ETL tools and frameworks.',
       'Knowledge of cloud computing platforms (e.g., AWS, Azure, GCP).',
       'Strong analytical and problem-solving abilities.',
       'Keen attention to detail and a commitment to data accuracy.',
       'Effective communication and teamwork skills.',
       'Willingness to learn and adapt to evolving data technologies.',
      'A passion for leveraging data engineering to drive meaningful social impact.',
    ],
    benefits:[
      'Competitive salary.',
      'Opportunities for professional growth and development',
      'Collaborative and inclusive work environment.',
      'Meaningful and impactful projects.',
      'Contribution to global challenges and solutions.',
      'Flexibility and work-life balance.',
    ], 
    footer:[
    'Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.\
    If you are ready to apply your data science skills to make a positive impact on the world,\
     we invite you to be part of our team.',
    'To apply, please complete this application form and upload your resume, ',
    ]
}
]

function JdataEngineer({ jobTitle, jobLocation, jobType, aboutUs, jobResponsibilities, jobDescription, jobRequirements,  benefits, footer }) {
  return (
    <div className='jobss'>
      <h2 className='title_head'><b>Job Title: {jobTitle}</b></h2>
      <p><strong>Job Location:</strong> {jobLocation}</p>
      <p><strong>Job Type:</strong> {jobType}</p>
      <div className='job_info'>
      <div>
      <p><strong>About Us:</strong></p>
      <p>{aboutUs}</p>
      </div>
      <div>
      <h3><b>Job Description:</b></h3>
      <p>{jobDescription}</p>
      </div>
      <div>
      <h3><b>Job Responsibilities:</b></h3>
      {jobResponsibilities && jobResponsibilities.length > 0 ? (
        <ul>
          {jobResponsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      ) : (
        <p>No job responsibilities listed.</p>
      )}
      </div>
      <div>
      <h3><b>Job Requirements:</b></h3>
      {jobRequirements && jobRequirements.length > 0 ? (
        <ul>
          {jobRequirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      ) : (
        <p>No job requirements listed.</p>
      )}
      </div>
      <div>
      <h3><b>Benefits:</b></h3>
      {benefits && benefits.length > 0 ? (
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      ) : (
        <p>No benefits listed.</p>
      )}
      </div>
      <div>
      {/*{footer && footer.length > 0 ? (
        <ul>
        {footer.map((foot, index) => (
          <li key={index}>{foot}</li>
        ))}
        <a href="https://forms.gle/3Yi12MxP3tw4pGL86'">here</a>
        </ul>
      ):(
        <p>no footer</p>
      )}*/}
      <div>
      Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.
    If you are ready to apply your data science skills to make a positive impact on the world,
     we invite you to be part of our team.
      </div>
      <div className='fooz'>
      To apply, please complete this application form and upload your resume, <a href="https://forms.gle/3Yi12MxP3tw4pGL86"><b>here.</b></a>
      </div>
      <div>Hamoye Foundation is an equal opportunity employer. 
    We celebrate diversity and are committed to creating an inclusive environment for all employees.
    </div>
      </div>
      {/*<button>Apply Now</button>*/}
      </div>
    </div>
  );
}

export default JdataEngineer;