import React from 'react';
import './jobs.scss'

export const jobListings3 = [
  {
    jobTitle: 'Junior DevOps',
    jobLocation: 'Remote',
    jobType: 'Contractor- Full-Time or Part-Time hours',
    aboutUs: 'Hamoye Foundation is dedicated to leveraging technology and data-driven solutions to address critical challenges in education, healthcare, infrastructure, and environmental sustainability. We are looking for a Junior DevOps Engineer to join our dynamic team, contributing to the development and maintenance of our infrastructure, deployment pipelines, and overall system reliability.',
    jobDescription : 'As a Junior DevOps Engineer at Hamoye Foundation, you will collaborate closely with our DevOps team to enhance our software development and deployment processes. This role is ideal for individuals who are passionate about DevOps practices, eager to learn, and committed to supporting projects that have a positive impact on communities and the environment.',

     jobResponsibilities:[
      'Work under the guidance of senior DevOps engineers to optimize and automate deployment pipelines.',
      'Assist with the setup and maintenance of infrastructure components.',
      'Contribute to monitoring, performance tuning, and troubleshooting efforts.',
      'Participate in building and managing CI/CD workflows.',
      'Collaborate with development and operations teams to ensure seamless deployments.',
      'Help maintain and enhance system configuration management.',
      'Assist in implementing security best practices for our infrastructure.',
      'Contribute to documentation of infrastructure and deployment processes',
      'Stay informed about DevOps trends and emerging technologies.'
    ],
    jobRequirements:[
      `Bachelor's degree in Computer Science, Information Technology, or a related field.`,
      'Familiarity with DevOps principles and practices.',
      'Basic understanding of containerization technologies (e.g., Docker, Kubernetes).',
      'Knowledge of infrastructure-as-code tools (e.g., Terraform, Ansible)..',
      'Experience with cloud computing platforms (e.g., AWS, Azure, GCP).',
      'Strong problem-solving and analytical skills..',
      ' Effective communication and teamwork abilities.',
      'Willingness to learn and adapt to evolving DevOps tools and techniques.',
      'Enthusiasm for contributing to impactful projects through DevOps.'
    ],
    benefits:[
      'Competitive salary.',
      'Opportunities for professional growth and development.',
      'Collaborative and inclusive work environment.',
      'Meaningful and impactful projects.',
      'Contribution to global challenges and solutions.',
      'Flexibility and work-life balance.',
    ], 
    footer:[
    'Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.\
    If you are ready to apply your data science skills to make a positive impact on the world,\
     we invite you to be part of our team.',
    'To apply, please complete this application form and upload your resume, ',
    
    // Other job details <a href="https://forms.gle/3Yi12MxP3tw4pGL86'">here</a>
    ]
}
]

function JuniorDevops ({ jobTitle, jobLocation, jobType, aboutUs, jobResponsibilities, jobDescription, jobRequirements,  benefits, footer }) {
  return (
    <div className='jobss'>
      <h2 className='title_head'><b>Job Title: {jobTitle}</b></h2>
      <p><strong>Job Location:</strong> {jobLocation}</p>
      <p><strong>Job Type:</strong> {jobType}</p>
      <div className='job_info'>
      <div>
      <p><strong>About Us:</strong></p>
      <p>{aboutUs}</p>
      </div>
      <div>
      <h3><b>Job Description:</b></h3>
      <p>{jobDescription}</p>
      </div>
      <div>
      <h3><b>Job Responsibilities:</b></h3>
      {jobResponsibilities && jobResponsibilities.length > 0 ? (
        <ul>
          {jobResponsibilities.map((responsibility, index) => (
            <li key={index} className='disc'>{responsibility}</li>
          ))}
        </ul>
      ) : (
        <p>No job responsibilities listed.</p>
      )}
      </div>
      <div>
      <h3><b>Job Requirements:</b></h3>
      {jobRequirements && jobRequirements.length > 0 ? (
        <ul>
          {jobRequirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      ) : (
        <p>No job requirements listed.</p>
      )}
      </div>
      <div>
      <h3><b>Benefits:</b></h3>
      {benefits && benefits.length > 0 ? (
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      ) : (
        <p>No benefits listed.</p>
      )}
      </div>
      <div>
      {/*{footer && footer.length > 0 ? (
        <ul>
        {footer.map((foot, index) => (
          <li key={index}>{foot}</li>
        ))}
        <a href="https://forms.gle/3Yi12MxP3tw4pGL86'">here</a>
        </ul>
      ):(
        <p>no footer</p>
      )}*/}
      <div>
      Join us at Hamoye Foundation to embark on a rewarding journey of data-driven change and innovation.
    If you are ready to apply your data science skills to make a positive impact on the world,
     we invite you to be part of our team.
      </div>
      <div className='fooz'>
      To apply, please complete this application form and upload your resume, <a href="https://forms.gle/3Yi12MxP3tw4pGL86"><b>here.</b></a>
      </div>
      <div>Hamoye Foundation is an equal opportunity employer. 
    We celebrate diversity and are committed to creating an inclusive environment for all employees.
    </div>
      </div>
      {/*<button>Apply Now</button>*/}
      </div>
    </div>
  );
}

export default JuniorDevops;