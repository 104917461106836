// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './landingpage.scss'
import children from '../../assets/images/children.webp'
import python  from '../../assets/images/python.svg'
import datasci  from '../../assets/images/datasci.webp'
import datastory  from '../../assets/images/datastory.webp'
import genAI  from '../../assets/images/genAI.webp'
import devops  from '../../assets/images/devops.webp'
import dataeng  from '../../assets/images/Dataeng.webp'
import backland from '../../assets/images/backland.svg'
import forwardland from '../../assets/images/forwardland.svg'
import union from '../../assets/images/Union.svg'
import union2 from '../../assets/images/Union2.svg'
import cattle from '../../assets/images/cattle.webp';
import rem from '../../assets/images/Rem.webp';
import malaria from '../../assets/images/malaria.webp';
import mixed from '../../assets/images/mixed.webp';
import specie from '../../assets/images/specie.webp';
import gelectric from '../../assets/images/gelectric.webp';
import forecast from '../../assets/images/forecast.webp';
import diet from '../../assets/images/diet.webp';
import LightBulbVideo from '../../assets/images/Light Bulb.mov';
import LightBulbpics from '../../assets/images/lightbulb.jpg'
import NavBar from '../../components/navigationbar/navigation';
import ConstructionOverlay from '../../components/construct/ConstructionOverlay';
import Contact from '../../sub-pages/contact/contact';
import Footer from '../../components/footer/footer';
import { useContact } from '../../context/ContactContext';

function LandingPage() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  //const handleOpenContact = () => dispatch({ type: APP_ACTIONS.OPEN_CONTACT });
  const [underConstruction, setUnderConstruction] = useState(true);

  const myInlineStyle = {
    marginTop:'25px'
  };

  const handleCloseOverlay = () =>{
    setUnderConstruction(false);
  }

  const { toggleContact } = useContact();
  return (
    <div className="landing-page">
                   {/*  {underConstruction && (
        <ConstructionOverlay onClose={handleCloseOverlay} />
      )}

     <Contact satus={satus} onClose={handleCloseOverlay} />*/}

       <header >
    
        <NavBar  />
       
        <img src={LightBulbpics} className='video' />
        <video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef} preload="auto">
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
      </header>
      
      <main>
        <section>
          <article>
          <div className="article1_co">

          <div className="description">
     
            <div className='child-img img-trick1'>
          <img src={children} alt="Icon" className="child-imgs" />   
          </div>      
          <div className="describe-title">Impacting Communities in Developing 
          Nations through AI-enabled Research and Career Training</div>
           <div className="describe-text"> As the saying goes, knowledge is power. 
           Join us in unlocking the potential of our communities with our impactful
            research initiatives. With your grants, we can harness the power of knowledge 
            to drive positive change and make a lasting difference in the lives of those we serve.</div>
            </div>
            <div className='child-img img-trick'>
          <img src={children} alt="Icon" className="child-imgs" />   
          </div>    
        </div>
          </article>
        </section>

        <section id="research-section">
          <article className='Research'>
        <div className='research_title'>Research Focus</div>
        <div  className='research_text'>Our research centers on enhancing infrastructure and sustainable practices to uplift communities, protect ecosystems, mitigate conflicts, 
        and combat health challenges, shortfall in education and electricity infrastructures.</div>
           
           <div className='research_images'>
           <div className='reasearch_pair'>
           <a href='/electrification' smooth={true} duration={500} >
           <div className='indv'>
           <img src={gelectric} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Electrification</b></div>
          <div className='research_atext'>to empower development,
            </div> 
            <div className='research_atext'> bridge disparities,
           improve lives
            </div> 
           </div>
           </a>
           <a href='/education' smooth={true} duration={500} >
           <div className='indv'>
           <img src={mixed} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Educational Infrastructure</b></div>
           <div className='research_atext'>
           to improve education and</div>
           <div className='research_atext'>
           foster future success</div>
           </div>
           
           </a>
  </div>

         <div className='reasearch_pair'style={myInlineStyle}>
         <a href='/remi' smooth={true} duration={500} >         
         <div className='indv'>
           <img src={rem} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Remittance Patterns</b></div>
           <div className='research_atext'>to understand economic support 
            </div>
            <div className='research_atext'>
            and development strategies</div>
           </div>  
           </a>     
           <a href='/climate' smooth={true} duration={500} >   
            <div className='indv' >
           <img src={forecast} alt="Icon" className="research-img" />
           <div className='research_atext '> <b>Climate Change and Social</b></div>
           <div className='research_atext '> <b>Conflicts</b></div>
           <div className='research_atext' >to understand conflicts and </div>
           <div className='research_atext' >societies and foster peace.</div>
           </div>
           </a>
           </div>
        
           <div className='reasearch_pair'>

           <a href='/infrastructure' smooth={true} duration={500} > 
           <div className='indv'>
           <img src={cattle} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Infrastructure Deficit

</b></div>
           <div className='research_atext'>addressing this deficit: AI's</div>
           <div className='research_atext'>transformative role in planning</div>
           </div>
           </a>
           <a href='/malaria' smooth={true} duration={500} >   
           <div className='indv'>
           <img src={malaria} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Malaria</b></div>
           <div className='research_atext'>persistent disease burden,
          </div>
            <div className='research_atext'>
            especially in tropical regions</div>
           </div> 
           </a>
           </div>

           <div className='reasearch_pair'>
           <a href='/food-security' smooth={true} duration={500} > 
           <div className='indv reindv'>
           <img src={diet} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Food Security and Access</b></div>
           <div className='research_atext'>to prevent malnutrition</div>
           <div className='research_antext'></div>
           </div>
           </a>
           <a href='/bio-diversity' smooth={true} duration={500} > 
           <div className='indv'>
           <img src={specie} alt="Icon" className="research-img" />
           <div className='research_atext'><b>Biodiversity Preservation</b></div>
           <div className='research_atext'>for conservation, ecosystem 
           </div>
            <div className='research_atext'>health,
            biodiversity preservation</div>
          </div>
           </a>
           </div>


           </div>
           
           </article>
           </section>

           <section id='Training-section'>
          <article className='Research'>
            <div className='program_head'>
        <div className='research_title'>Programs we offer</div>
        <div className='navigate_prog'>
        <img src={backland} alt="Icon" className="foback" 
          onClick={scrollLeft}/>
        <img src={forwardland} alt="Icon" className="foback" 
        onClick={scrollRight}/>
        </div>
        </div>
        <div  className='research_text'>As part of our mission of developing our communities, we provide free training to College graduates or those who have basic programming 
        experience to help launch their sustainable professional careers.</div>

           <div className='program_list' ref={scrollRef}>
           <a href="https://www.hamoye.com/programs/generative-AI" >
          <div className='program_blocks'>
           <img src={genAI} alt="Icon" className="program-img" />
           <div className='topix'>Generative AI</div>
           <div className='describx'> Interns will explore the basics, delve into prompt engineering, and use large language models for practical applications. It wraps up with a project that brings everything together, allowing you to build an AI application 
      
             </div>
            <div className='Learn'>Learn more</div>
           </div>
           </a>  

           <a href="https://www.hamoye.com/programs/data-science" >
          <div className='program_blocks'>
           <img src={datasci} alt="Icon" className="program-img" />
           <div className='topix'>Data Science</div>
           <div className='describx'> In this program you will learn to develop 
            expertise in machine learning techniques  required to collect,
             clean, analyze, and interpret large datasets to derive valuable 
             insights and solve real-world problems.
             </div>
            <div className='Learn'>Learn more</div>
           </div>
           </a>  
           
           <a href="https://www.hamoye.com/programs/data-engineering" >
           <div className='program_blocks'>
           <img src={dataeng} alt="Icon" className="program-img" />
           <div className='topix'>Data Engineering</div>
           <div className='describx2'> This program focuses on the fundamentals of 
           designing, building, and maintaining the infrastructure and tools 
           necessary to process and manage large-scale data pipelines efficiently. 
             </div>
            <div className='Learn'>Learn more</div>
           </div>
          </a>

          <a href="https://www.hamoye.com/programs/devops/" >
           <div className='program_blocks'>
           <img src={devops} alt="Icon" className="program-img" />
           <div className='topix'>DevOps</div>
           <div className='describx'> Learn the principles that enable collaboration 
           between software development and IT operations teams to automate 
           the software delivery process and improve the reliability 
           and security of applications. 
             </div>
            <div className='Learn'>Learn more</div>
           </div>
           </a>
           
           <a href="https://www.hamoye.com/programs/data-story-telling/" >
           <div className='program_blocks'>
           <img src={datastory} alt="Icon" className="program-img" />
           <div className='topix'>Data Storytelling</div>
           <div className='describx'> This program teaches how to communicate 
           insights from data using visualizations, narratives, and other 
           storytelling techniques to make data-driven decisions and 
           influence stakeholders effectively.
             </div>
            <div className='Learn'>Learn more</div>
           </div>
           </a>
           <a href="https://www.hamoye.com/programs/high-school-bootcamp" >
           <div className='program_blocks'>
           <img src={python} alt="Icon" className="program-img" />
           <div className='topix'>Python for High School
           Bootcamp</div>
           <div className='describx'> This bootcamp promises an 
           immersive journey into the world of Python programming, 
           where you’ll learn the fundamentals, build exciting projects, 
           and unleash your creativity while gaining valuable coding skills.
             </div>
            <div className='Learn'>Learn more</div>
           </div>
           </a>

           </div>
           </article>
           </section>

           <section>
          <article className='programfacts'>
        <div className='facts_title'>Program Facts</div>
        <div  className='facts_text'>
        Our program has attracted a diverse and dynamic community, 
        including a significant representation of accomplished
         women and dedicated program fellows.

           </div>
           <div className='facts_nos'>

            
           <div className='facts_nos_styl'>
            <span className='styl_1'>22K+</span>
            <span className='styl_2'>Number of Registrations</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'>4K+</span>
            <span className='styl_3'>Number of People who have completed Core Courses</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'>~30%</span>
            <span className='styl_3'>Percentage of Females who register or 
            complete the internship program</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'>400+</span>
            <span className='styl_2'>Hamoye Fellows</span>
           </div>

           </div>

               <div className='facts_nos'>

            
           <div className='facts_nos_styl'>
            <span className='styl_1'>12K+</span>
            <span className='styl_2'>Number of Codes 
             committed in Github</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'>100+</span>
            <span className='styl_3'>Number of Real World based Capstone Projects</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'>11</span>
            <span className='styl_3'>Number of Internship Cohorts Completed</span>
           </div>

           <div className='facts_nos_styl'>
            <span className='styl_1'> Humanity</span>
            <span className='styl_2'>  Our Number One Focus</span>
           </div>

           </div>
           </article>
           </section>
          

          
           <section className='partners-section1'>
            
           <div className='partners'>
           <img src={union} alt="Icon" className="union" />
            <div className='partners_title'>Partner with Us</div>
           <button className='partner_butts' onClick={toggleContact}>Contact Us</button>
           <img src={union2} alt="Icon" className="union2" />
         
            </div>
            
           </section>

          {/*} <section className='partners-section'>

           <div className='partners'>
            <div className='partners_title'>Our Partners</div>
            <img src={partners} alt="Icon" className="partner_ad" />
            </div>
  
  </section>*/}

   
      </main>
      <Footer/>
    </div>
  );
}

export default LandingPage;
