// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/infa1.png'
import aelectrify from '../../assets/images/infa2.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Infrastructure() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Infrastructure Deficit</div>
           <div className='electric_speech'>
           Hamoye is exploring the use of AI models and tools to analyze vast datasets related to transportation, energy, water supply, healthcare, and other vital sectors. By leveraging AI, we aim to identify patterns in existing infrastructure deficits, predict future demands, and derive insights for investment and policy.
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif'/>
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Unlocking Africa's Potential: Bridge the Infrastructure Gap</div>
           <div className='electric_speech'>
           Africa, despite its immense potential, grapples with a significant infrastructure deficit that poses challenges to its economic growth, social development, and overall well-being of its population. This deficit encompasses a wide range of sectors including transportation, energy, water and sanitation, healthcare, education, and more. The insufficiency of adequate infrastructure hampers productivity, limits access. For example, approximately 70% of Africa's roads are unpaved, affecting transportation efficiency and connectivity and the continent's rail network is significantly underdeveloped, constraining the movement of goods and people. Only about 30% of the population in sub-Saharan Africa has access to reliable electricity services and about 40% of the population in sub-Saharan Africa lacks access to safe drinking water sources.
          </div>
        </article>
       </section>

       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>
           We are exploring data, models and tools in the following areas: 
           <ul className='bullet-list'>
           <li>Transportation</li> 
           <li>Water and sanitation</li> 
           <li>Healthcare</li> 
           <li>Electricity</li> 
        </ul>
           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>

   

  
      </main>
     <Footer/>
    </div>
  );
}

export default Infrastructure;
