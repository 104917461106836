import { createContext, useContext, useState } from 'react';

const ContactContext = createContext();

export function ContactProvider({ children }) {
    const [satus, setSatus] = useState('close');

  const toggleContact = () => {
    setSatus('open');
  };

  const closeContact = () => {
    setSatus('close');
  };

  return (
    <ContactContext.Provider value={{ satus, toggleContact , closeContact}}>
      {children}
    </ContactContext.Provider>
  );
}

export function useContact() {
  return useContext(ContactContext);
}
