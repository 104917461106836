// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/malaria_new.svg'
import aelectrify from '../../assets/images/big_malaria.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Malaria() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Malaria</div>
           <div className='electric_speech'>
           Africa bears the highest burden of malaria cases worldwide, 
           accounting for about 94% of global malaria cases and deaths.
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif' />
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Malaria kills the future</div>
           <div className='electric_speech'>
           Children under five years of age and pregnant women are the most vulnerable to
            severe malaria infection. In Africa, a child dies from malaria every two minutes. 
            Malaria places a significant economic burden on African countries.
             It hampers economic growth due to healthcare costs, it reduces productivity due
              to absenteeism from work and school and it kills to many people. 
              Thankfully, malaria is preventable and treatable if diagnosed and treated promptly. 

           </div>
        </article>
       </section>

       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>
           Hamoye is exploring AI based techniques to help understand patterns
            and make predictions in areas such as epidemiological surveillance,
             vector control, healthcare resource allocation, education and awareness, and behavioral analysis.
           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>

   

  
      </main>
     <Footer/>
    </div>
  );
}

export default Malaria;
