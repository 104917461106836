import { createContext, useContext, useReducer, useEffect } from "react";
import { paths } from "../configs/paths";

export const APP_ACTIONS = {
  OPEN_CONTACT: "OPEN_CONTACT",
  OPEN_NAV: "OPEN_NAV",
  CLOSE_NAV: "CLOSE_NAV",
  CLOSE_CONTACT: "CLOSE_CONTACT",
  CLOSE_ALL: "CLOSE_ALL",
  SET_LOGGED_IN: "SET_LOGGEN_IN",
};

export const AppContext = createContext({});

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context;
};

const AppProvider = ({ children }) => {
  const initialState = {
    openContact: false,
    loggedIn: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case APP_ACTIONS.OPEN_CONTACT:
        return { ...state, openContact: true };

      case APP_ACTIONS.CLOSE_CONTACT:
        return { ...state, openContact: false };

      case APP_ACTIONS.SET_LOGGED_IN:
          return { ...state, loggedIn: true };

      default:
        break;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetch(paths.me, {
      cache: 'no-cache',
      credentials: 'include'
    }).then(response => {
      if(response.status === 200) {
        dispatch({ type: APP_ACTIONS.SET_LOGGED_IN });
      }
    });
  }, [dispatch, APP_ACTIONS.SET_LOGGED_IN]);

  const contexts = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={contexts}>{children}</AppContext.Provider>;
};

export default AppProvider;
