export const paths = {
  dataScience: "/programs/data-science",
  dataEngineering: "/programs/data-engineering",
  devOps: "/programs/devops",
  dataStoryTelling: "/programs/data-story-telling",
  highSchoolBootCamp: "/programs/high-school-bootcamp",
  about: "#!",
  programs: "/#programs",
  testimonials: "/#testimonials",
  contact: "#contact-modal-open",
  signUp: "https://app.hamoye.com/signup",
  privacy: "/privacy",
  blog: "https://hamoyehq.medium.com",
  apply: "https://hamoyehq.medium.com/list/upcoming-cohort-9105ca1a9f4f",
  help: "/home/help-center",
  howItWorks: "/home/the-platform",
  ourMission: "/home/our-mission",
  terms: "/terms",
  linkedIn: "https://www.linkedin.com/company/hamoyehq",
  facebook: "https://www.facebook.com/hamoyehq",
  twitter: "https://twitter.com/HamoyeHQ",
  youtube: "https://www.youtube.com/c/hamoyehq",
  instagram: "https://www.instagram.com/hamoyehq",
  placeholder: "#!",
  application: "https://app.hamoye.com",
  courseSearch: "https://app.hamoye.com/search/courses",
  signIn: "https://app.hamoye.com/login",
  signUp: "https://app.hamoye.com/signup",
  me: "https://api.hamoye.com/api/v/16/core/users/me",
};
