import React, { useState } from 'react';
import { Link, Element } from 'react-scroll';
import privacyConfig from '../../configs/privacyConfig';
import './privacy.scss';
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';

const Privacy = () => {
    const [activeLink, setActiveLink] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const isMobile = window.innerWidth <= 768;
  const handleLinkClick = (label) => {
    if (label === selectedItem) {
        // If the same item is clicked, close it
        setActiveLink('');
        setShowContent(false);
        setSelectedItem(null);
      } else {
        // If a different item is clicked, open it
        setActiveLink(label);
        setShowContent(true);
        setSelectedItem(label);
      }
  };

  return (
    <div className="termprivacy-page">
     <div className="Navbar">
     <NavBar/>
      </div>
      <div className="privacy-header">
        <div className='titl'>Privacy</div>
      </div>
      <div className="privacy-container">
        <div className="privacy-menu">
          <ul>
            {privacyConfig.map((item, index) => (
              <li key={index}>
                <Link
                  to={isMobile ? 'dummy, so it dont scroll..lool': item.label || 'Welcome'}
                  smooth={true}
                  offset={isMobile ? 0 : -150}
                  exact={true}
                  duration={500}
                  className={activeLink === item.label ? 'active' : ''}
                  onClick={() => handleLinkClick(item.label)}
                  
                >
                  {item.label || 'Welcome'}
                  
                </Link>
                {showContent && selectedItem === item.label && (<span className="description" id={item.label}>{item.description}</span>)}
              </li>
            ))}
          </ul>
        </div>
        <div className="privacy-content">
        <div className='titl'>  Updated October 1, 2023</div>
          {privacyConfig.map((item, index) => (
            <div key={index} id={item.label} name={item.label || 'Welcome'} className="privacy-section">
              <h2><b>{item.label || ''}</b></h2>
              <p>{item.description}</p>
            </div>
          ))}

        </div>
      </div>
      <div className="foot">
      <Footer/>
      </div>
    </div>
  );
};

export default Privacy;
