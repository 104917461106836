import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/Landingpage/landingpage';
import Electrification from './pages/Electrification/Electrification';
import Malaria from './pages/Electrification/malaria';
import Remi from './pages/Electrification/remitenance';
import Footer from './components/footer/footer';
import ContactForm from './sub-pages/contact/contact';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Climate from './pages/Electrification/climate';
import Foodsecurity from './pages/Electrification/foodsecurity';
import Education from './pages/Electrification/education';
import Biodiversity from './pages/Electrification/biodiversity';
import Infrastructure from './pages/Electrification/infrastructure';
import Career from './pages/Electrification/Career';
import Privacy from './pages/information/privacy';
import Terms from './pages/information/term';
import Contact from './sub-pages/contact/contact';
import OurStory from './pages/Electrification/Our Story';
import { useContact } from './context/ContactContext';

function App() {
  const { satus, closeContact } = useContact();

  return (
    <div className="App">
   <Contact satus={satus} onClose={closeContact} />
       <Router>
        <Routes>
      
        <Route path="/" element={<LandingPage/>} />
        <Route path="/electrification" element={<Electrification/>} />
        <Route path="/climate" element={<Climate/>} />
        <Route path="/education" element={<Education/>} />
        <Route path="/food-security" element={<Foodsecurity/>} />
        <Route path="/bio-diversity" element={<Biodiversity/>} />
        <Route path="/infrastructure" element={<Infrastructure/>} />
        <Route path="/malaria" element={<Malaria/>} />
        <Route path="/remi" element={<Remi/>} />
        <Route path="/careers" element={<Career/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/OurStory" element={<OurStory/>} />
          </Routes>
       
  </Router>
        
  
    </div>
  );
}

export default App;
