import React, { useState , useRef, useEffect }from 'react';
import './footer.scss'
import iconImage from '../../assets/images/hamoye.png'
import facebook from '../../assets/images/facebook.svg'
import youtube from '../../assets/images/youtube.svg'
import instagram from '../../assets/images/instagram.svg'
import email from '../../assets/images/email.svg'
import twitter from '../../assets/images/twitter.svg'
import linkedin from '../../assets/images/linkedin.svg'
import arrow from '../../assets/images/new_arrow.svg'
import footerConfig from "../../configs/footerConfig";
import { APP_ACTIONS, useAppContext } from "../../context/AppContext";
import Contact from '../../sub-pages/contact/contact';
import { NavHashLink } from 'react-router-hash-link';
import { useContact } from '../../context/ContactContext';

const Footer = () => {
  const {  more, support } = footerConfig;
  const { dispatch } = useAppContext();
  //const handleOpenContact = () => dispatch({ type: APP_ACTIONS.OPEN_CONTACT });
  const [satus, setSatus] = useState('close');
  const handleOpenContact = () =>{
    //setContact(true);
    setSatus('open')
  }

  const handleCloseOverlay = () =>{
   /// setContact(false);
   setSatus('close')
  }

      
  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
    
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { toggleContact } = useContact();

  return (
    <footer className="footer_land">
          
          {/*<Contact satus={satus} onClose={handleCloseOverlay} />*/}
      
      <div className="footer_icons">
        <div>
        <a href='/'>
          <img src={iconImage} alt="Icon" className='iaon-img'/>
          </a>
        </div>
        <div>
          <div className="footer_row">
            <div className="footer_speech">
              Our mission at Hamoye Foundation is to provide meaningful research
              with the help of grants to benefit developing communities.
              We also provide free training to individuals in AI and Tech related fields.
              Hamoye is a 501(c)(3) organization.
            </div> 
            <div className="icons_social">
            <a href="https://www.linkedin.com/company/hamoyehq" >
              <img src={linkedin} alt="Icon" className="social"/>
              </a>
              <a href="https://www.facebook.com/hamoyehq" >
              <img src={facebook} alt="Icon" className="social" />
              </a>
              <div onClick={toggleContact}>
              <img src={email} alt="Icon" className="social"/>
              </div>
              <a href="https://www.instagram.com/hamoyehq/" >
              <img src={instagram} alt="Icon" className="rocial" />
              </a>
              <a href="https://www.youtube.com/c/hamoyehq" >
              <img src={youtube} alt="Icon" className="social" />
              </a>
              <a href="https://twitter.com/HamoyeHQ" >
              <img src={twitter} alt="Icon" className="social"/>
              </a>
            </div>
          </div>

          <div className="bordera"></div>
          <div className="footer_row">
            <div className="partner_foot">
              <div className="footer_titi" onClick={toggleContact}>
                Partner With Us/Contact
              </div>
              <div className="footer_titi2" onClick={toggleWorkPlank}>
                How We Work
              </div>
              {showWorkPlank && (<div className='footer_titi3' ref={workPlankRef}>
             
             <NavHashLink   to='/#research-section'  
              smooth={500} duration={5000} spy={true} exact="true" >
               <div >Research</div>
               </NavHashLink>
                        {/*<div className='divider'> </div>*/}
                        <NavHashLink  className="footer_titi3"  to='/#Training-section'
                        smooth={500} duration={5000} spy={true} exact="true">
               <div >Training Program</div>
               </NavHashLink>
           </div>)}
           <a href='/OurStory'>
              <div className="footer_titi2">
                Our Story
              </div>
              </a>
              <a href="/careers" >
              <div className="footer_titi2">
                Careers
              </div>
              </a>
              <a href="https://hamoyehq.medium.com/" >
              <div className="footer_titi2">
              Blog
              </div>
              </a>
              <a href="/privacy" >
              <div className="footer_titi2">
                Privacy
              </div>
              </a>
              <a href="/terms" >
              <div className="footer_titi2">
                Terms
              </div>
              </a>
            </div>
            <form method="POST" action="https://api.hamoye.com/sendysender/subscribe" className="news">
              <div className="subscribe">Subscribe to our newsletter</div>
              <div className="newsleta_input">
                <input required type="email" name="username" id="username"
                  placeholder="Enter your email" className='newsleta' />
                <button type="submit" className="newsleta_submit">
                  <img src={arrow} alt="Icon" width="24" height="24" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
