const privacyConfig = [
    {
      label: "",
      description: `Welcome to Hamoye Foundation's Privacy Policy. Hamoye Foundation ("we," "our," or "us") is a charitable foundation dedicated to advancing tech-related research and providing free training through grants received from various organizations. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services and visit our website. By accessing our website or using our services, you consent to the practices described in this policy.`,
    },
    {
      label: `Information We Collect`,
      description: (
        <div>
        <div style={{marginBottom:'30px'}}>
          We may collect different types of information to fulfill our mission effectively:
          </div>
          <div style={{marginBottom:'30px'}}>
            <h3 >Personal Information</h3>
            <ul>
            <li>
              Contact Information: We may collect your name, email address, phone number, and postal address when you submit forms or communicate with us.
            </li>
            <li>
              Account Information: If you create an account on our website, we may collect information related to your account, such as a username and password.
            </li>
            </ul>
          </div>
          <div>
            <h3>Non-Personal Information</h3>
            <ul>
            <li>
              Usage Data: We collect information about your interactions with our website and services, including the pages you visit, the links you click, and the content you access.
              </li>
              <li>
              Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your experience on our website and services. You can manage your cookie preferences using your browser settings.
              </li>
              </ul>
          </div>
        </div>
        
      ),
    },
    
    {
      label: `How We Use Your Information`,
      description: (
        <div>We use the information we collect for various purposes, including but not limited to:
        <ul>
      
          <li>To provide, personalize, and improve our services.</li>
          <li>To communicate with you and respond to your inquiries.</li>
          <li>To monitor and analyze website usage, trends, and user behavior.</li>
          <li>To protect our rights, privacy, safety, or property, and/or that of you or others.</li>
          <li>To comply with any applicable laws and regulations.</li>
        </ul>
        </div>
      ),
    },
    {
      label: `Data Sharing and Disclosure`,
      description: (
        <div>
          We may share your information in the following circumstances:
          <ul>
            <li>With your consent.</li>
            <li>With trusted third-party service providers who help us deliver our services.</li>
            <li>In response to legal requests and obligations.</li>
            <li>In connection with a merger, sale, change in control, or other business transfer.</li>
          </ul>
        </div>
      ),
    },
    {
      label: `Security`,
      description: `We implement reasonable measures to protect your personal information, but it is important to note that no method of data transmission or storage is entirely secure. Therefore, we cannot guarantee the absolute security of your information.`,
    },
    {
      label: `Your Choices`,
      description: (
        <div>
          You have certain choices concerning your personal information:
          <ul>
            <li>You can access, correct, or delete your personal information by contacting us.</li>
            <li>You may opt out of receiving promotional communications from us.</li>
          </ul>
        </div>
      ),
    },
    
    {
      label: `Changes to this Privacy Policy`,
      description: `We may update this Privacy Policy periodically to reflect changes in our practices and services. We will post the revised policy with an updated effective date on our website.
      `,
    },
    {
      label: `Contact Us`,
      description: `If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
      wecare@hamoye.org`,
    },
  ];
  
  export default privacyConfig;
  


 