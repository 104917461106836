import React, { useState , useRef, useEffect }from 'react';
import './navigation.scss'
import iconImage from '../../assets/images/hamoye.png'
import downarrow from '../../assets/images/down arrow.svg'
import hamburger from '../../assets/images/hamburger.svg'
import { RiCloseFill } from "react-icons/ri";
import { NavHashLink } from 'react-router-hash-link';
import { useContact } from '../../context/ContactContext';

const NavBar = () => {
    //code for how we work
      const [showWorkPlank, setShowWorkPlank] = useState(false);
      const workPlankRef = useRef(null);
      
      const toggleWorkPlank = () => {
        setShowWorkPlank(!showWorkPlank);
        
      };
    
      const handleClickOutside = (event) => {
        if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
          // Clicked outside the work plank, so close it
          setShowWorkPlank(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      const [showNav, setShowNav] = useState(false);
    
      const toggleNav = () => {
        setShowNav(!showNav);
      };

      const { toggleContact } = useContact();
  return (
    
    <div className='header-nav'>

       
      
      <div className='title_icon'>
        <div className='hamburger-menu' onClick={toggleNav}>
          <img src={hamburger} className='ambug' />
        </div>
        <div className="hamoye-icon" >
        <a href='/'>
          <img src={iconImage} alt="Icon" className="icon-img" />
          </a>
        </div>
      </div>

      <nav className={`nav-menu ${showNav ? 'open' : ''}`}>
        <div className='hambug_icon'>
     
          <div  >{/*className='hamburger-menu'*/}
          <div className='rmbug'>
         <RiCloseFill             
                size={50} 
                className='text-black'  
                onClick={toggleNav}            
              />
              </div>
           {/* <img src={hamburger} className='mbug' />*/}
          </div>
          <div className="hamoye-icon2">
          <a href='/'>
            <img src={iconImage} alt="Icon" className="icon-img2" />
            </a>
          </div>
        </div>

        <ul>
          <li>
            <div className='workli' onClick={toggleWorkPlank}>
              <div className='work'><a >How We Work</a>
                <img
                  src={downarrow}
                  alt="Icon" 
                  className={`${showWorkPlank ? 'rotate-up' : 'arrow'}`}
                />
              </div>
              {showWorkPlank && (<div className='work_plank' ref={workPlankRef}>
             
                <NavHashLink   className='res' to='/#research-section' onClick={toggleNav} 
                 smooth={500} duration={5000} spy={true} exact="true" >
                  <div >Research</div>
                  </NavHashLink>
                           {/*<div className='divider'> </div>*/}
                           <NavHashLink   className='res' to='/#Training-section' onClick={toggleNav}
                           smooth={500} duration={5000} spy={true} exact="true">
                  <div >Training Program</div>
                  </NavHashLink>
              </div>)}
            </div>
          </li>
          <li><a href="/OurStory">Our Story</a></li>
          <li><a href="/careers" >Careers</a></li>
          <li> <a href="https://hamoyehq.medium.com/" >Blog</a></li>
          <li onClick={toggleContact} className='point'><a>
            Contact</a></li>       
        </ul>
      </nav>
      <div></div>
    </div>
  );
};

export default NavBar;
