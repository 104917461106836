// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/electrify.svg'
import aelectrify from '../../assets/images/article_electric.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Electrification() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Electrification</div>
           <div className='electric_speech'>
           Poor electrification has a significant impact on the economies of developing nations. 
           Hamoye is using AI 
           to research the issues that could help turn the situation around
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif'/>
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Electrification is possibly the biggest opportunity in Africa </div>
           <div className='electric_speech'>
           Electrification is a huge challenge for the biggest economies in Africa. 
           Poor electrification severely impacts the economies of these nations in 
           several ways, including the hampering of economic productivity and growth, 
           stunted industrialization, and barriers to adaptation of modern technology. 
           The challenges are enormous, including access disparity, intermittent supply, high costs, 
           lack of grid infrastructure, climate change and poor planning. At Hamoye, we believe that 
           solving the challenge of electrification could be the biggest opportunity to put Africa on 
           the path to sustainable development. Expanding access to electricity in these countries can 
           catalyze economic development by powering industries, improving healthcare services, 
           and enhancing educational opportunities. Additionally, it can reduce reliance on environmentally
            harmful energy sources, mitigating climate change and reducing health risks associated with indoor 
            air pollution. It is a fundamental step toward leveling the playing field in global competitiveness.
           </div>
        </article>
       </section>

       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>
           Some of our topics of interest in this area include access mapping, 
           renewables adaptation, microgrid integration and optimization, grid management,
            policy and market design.
           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>

   

  
      </main>
     <Footer/>
    </div>
  );
}

export default Electrification;
