// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import electrify from '../../assets/images/mainclimate.png'
import aelectrify from '../../assets/images/mainclimate2.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function Climate() {

  
  const videoRef = useRef(null);
useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Set the starting time to 3 seconds
      video.currentTime = 3;

      // Listen for the 'timeupdate' event to loop the video between 3 and 4 seconds
      const onTimeUpdate = () => {
        if (video.currentTime >= 5) {
          video.currentTime = 3.2// Reset to 3 seconds to loop
        }
      };

      video.addEventListener('timeupdate', onTimeUpdate);

      // Clean up the event listener when the component unmounts
      return () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, []);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400, // Adjust the value to scroll by the desired amount
      behavior: 'smooth',
    });
  };

  const [showWorkPlank, setShowWorkPlank] = useState(false);
  const workPlankRef = useRef(null);
  
  const toggleWorkPlank = () => {
    setShowWorkPlank(!showWorkPlank);
  };

  const handleClickOutside = (event) => {
    if (workPlankRef.current && !workPlankRef.current.contains(event.target)) {
      // Clicked outside the work plank, so close it
      setShowWorkPlank(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
    <div className="electrify-page">
  
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>
      <section>
          <article className='electricfacts'>
            <div className='elect'>
           <div className='electrify_title'>Climate Change & Social Conflicts</div>
           <div className='electric_speech'>
           There is a lack of definitive, transparent,
            data-driven research on climate migration in Africa.
             To help inform policy and drive the needed investments towards resilience 
             and adaptation, Hamoye is using AI to understand the impact of climate change 
             on migration patterns and the associated social economic impacts.
           </div>
           </div>
            <div className='electrif_img'>
              <img src={electrify} className='img_electrif'/>
            </div>
           </article>
       </section>
       
       <section>
        <article className='electricb_speech'>
          <div className='aelectric_img'>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electrify_title'>Could climate spark conflict?</div>
           <div className='electric_speech'>
           Africa is emerging as a major theater for climate migration. Migration patterns are changing within the continent and outward from the continent. 
           The resulting social economic impacts are already being felt on the continent, an example of which is 
           the large-scale conflicts arising from the southward migration of the Fulani herdsmen of the Sahel region.
           </div>
        </article>
       </section>
      
       <section>
          <article className='Researchfacts'>
            <div className='elect'>
           <div className='electrify_title'>Research Goals</div>
           <div className='electric_speech'>
           We are working on data, models and tools to:
           <ul className='bullet-list'>
           <li>Predict future migration patterns</li> 
           <li>Identify vulnerable populations</li> 
           <li>Develop early warning systems for climate-related hazards</li> 
           <li>Design and implement adaptation and resilience measures</li> 
        </ul>
           </div>
           </div>
            {/*<div className='electrif_img'>
              <img src={electrify} />
              </div>*/}
           </article>
       </section>
   

  
      </main>
     <Footer/>
    </div>
  );
}

export default Climate;
