// src/components/LandingPage.js

import React from 'react';
import './Electrification.scss'
import { useNavigate } from "react-router-dom"
import aelectrify from '../../assets/images/career1.png'
import NavBar from '../../components/navigationbar/navigation';
import { Link } from 'react-scroll';
import { NavHashLink } from 'react-router-hash-link';
import Jdatascientists, { jobListings } from '../../configs/jobs/Jdatascientist';
import JdataEngineer, { jobListings2 } from '../../configs/jobs/JdataEngineer';
import JuniorDevops, { jobListings3 }  from '../../configs/jobs/.JuniorDevops';
import JDatastory, { jobListings4 }  from '../../configs/jobs/JDatastory';
import AProgramcord, { jobListings5 } from '../../configs/jobs/AProgramcord';
import Footer from '../../components/footer/footer';


function Career() {

  const navigate = useNavigate()
  const myInlineStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    marginTop:'1rem'
  };
  const myInlineStyle3 = {
    marginTop:'2rem'
  };
  const myInlineStyle2 = {
    marginTop:'3rem'
  };
  const myInlineStyle4 = {
    cursor:'pointer'
  };
  
  const handleSectionToggle = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section.style.display === 'none' || section.style.display === '') {
      section.style.display = 'block';
    } else {
      section.style.display = 'none';
    }
    section.scrollIntoView({ behavior: 'smooth' });
  };

  
  return (
    <div className="electrify-page">
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>     
       <section>
        <article className='electricb_speech '>
          <div className='electrify_title career' style={myInlineStyle}>Your Career Starts Here.</div>
           <div className='electric_speech'>
           Join us in a journey of purpose and impact. At Hamoye, we are on a mission to drive positive change 
           on a global scale. Our work revolves around improving infrastructure, fostering sustainability,
            addressing health challenges, and promoting education and electrification. We are a diverse and innovative team, united by a common passion to make a difference. By joining our team, 
            you will have the opportunity to contribute your skills and ideas to meaningful projects
             that have the power to uplift communities, protect ecosystems, and create a brighter future for 
             all. If you are driven by purpose and seek to be part of a dynamic team dedicated to making the world
              a better place, we invite you to apply and be part of our journey towards transformative impact and 
              growth. 
           Your career at Hamoye is not just a job; it's an opportunity to be a catalyst for positive change.
          </div>
          <div className='aelectric_img' style={myInlineStyle2}>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
          <div className='electric_speech'>Although we give preference to individuals who complete our  <NavHashLink to='/#Training-section' style={myInlineStyle4}><b>4-month internship programs</b></NavHashLink>,
           we would like to hear from you.  Below are the current job openings:</div>
           <Link  to="juniordatasci" smooth={500} duration={500} spy={true} exact="true" 
           onClick={() => handleSectionToggle('juniordatasci')}
           className='pont'><div className='electric_speech' style={myInlineStyle3}><b>Junior Data Scientists</b></div></Link>
            <Link  to="juniordataEngineer" smooth={500} duration={500} spy={true} exact="true" 
            onClick={() => handleSectionToggle('juniordataEngineer')}
           className='pont'> <div className='electric_speech' style={myInlineStyle3}><b>Junior Data Engineers</b></div></Link>
             <Link  to="juniordevops" smooth={500} duration={500} spy={true} exact="true" 
            onClick={() => handleSectionToggle('juniordevops')}
           className='pont'>
            <div className='electric_speech' style={myInlineStyle3}><b>Junior DevOps</b></div>
            </Link>
            <Link  to="jdatastory" smooth={500} duration={500} spy={true} exact="true" 
            onClick={() => handleSectionToggle('jdatastory')}
           className='pont'>
           <div className='electric_speech' style={myInlineStyle3}><b>Junior Data Storyteller</b></div>
           </Link>
           <Link  to="jAprogramcord" smooth={500} duration={500} spy={true} exact="true" 
            onClick={() => handleSectionToggle('jAprogramcord')}
           className='pont'>
           <div className='electric_speech' style={myInlineStyle3}><b>Assistant Program Coordinator</b></div>
           </Link>
          
        </article>
       </section>

       <section id="juniordatasci">
        {jobListings.map((job, index) => (
          <Jdatascientists
            key={index}
            jobTitle={job.jobTitle}
            jobLocation={job.jobLocation}
            jobType={job.jobType}
            aboutUs={job.aboutUs}
            jobResponsibilities={job.jobResponsibilities}
            jobDescription={job.jobDescription}
            jobRequirements={job.jobRequirements}
            benefits={job.benefits}
            footer={job.footer}
          />
        ))}
      </section>
  
      <section id="juniordataEngineer">
      {jobListings2.map((job, index) => (
      <JdataEngineer
         key={index}
            jobTitle={job.jobTitle}
            jobLocation={job.jobLocation}
            jobType={job.jobType}
            aboutUs={job.aboutUs}
            jobResponsibilities={job.jobResponsibilities}
            jobDescription={job.jobDescription}
            jobRequirements={job.jobRequirements}
            benefits={job.benefits}
            footer={job.footer}
      />
      ))}
      </section>

      <section id="juniordevops">
      {jobListings3.map((job, index) => (
          <JuniorDevops
         key={index}
            jobTitle={job.jobTitle}
            jobLocation={job.jobLocation}
            jobType={job.jobType}
            aboutUs={job.aboutUs}
            jobResponsibilities={job.jobResponsibilities}
            jobDescription={job.jobDescription}
            jobRequirements={job.jobRequirements}
            benefits={job.benefits}
            footer={job.footer}
      />
      ))}
      </section>

      <section id="jdatastory">
      {jobListings4.map((job, index) => (
      <JDatastory
         key={index}
            jobTitle={job.jobTitle}
            jobLocation={job.jobLocation}
            jobType={job.jobType}
            aboutUs={job.aboutUs}
            jobResponsibilities={job.jobResponsibilities}
            jobDescription={job.jobDescription}
            jobRequirements={job.jobRequirements}
            benefits={job.benefits}
            footer={job.footer}
      />
      ))}
      </section>

      <section id="jAprogramcord">
      {jobListings5.map((job, index) => (
      <AProgramcord
         key={index}
            jobTitle={job.jobTitle}
            jobLocation={job.jobLocation}
            jobType={job.jobType}
            aboutUs={job.aboutUs}
            jobResponsibilities={job.jobResponsibilities}
            jobDescription={job.jobDescription}
            jobRequirements={job.jobRequirements}
            benefits={job.benefits}
            footer={job.footer}
      />
      ))}
      </section>
      </main>

      <Footer/>
    </div>
  );
}

export default Career;

