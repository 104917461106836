// src/components/LandingPage.js

import React, { useState , useRef, useEffect }from 'react';
import './Electrification.scss'
import { useNavigate } from "react-router-dom"
import aelectrify from '../../assets/images/Ourstory.png'
import NavBar from '../../components/navigationbar/navigation';
import Footer from '../../components/footer/footer';


function OurStory() {

  const navigate = useNavigate()
  const myInlineStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    marginTop:'1rem'
  };
  const myInlineStyle3 = {
    marginTop:'2rem'
  };
  const myInlineStyle2 = {
    marginTop:'3rem'
  };
  const myInlineStyle4 = {
    cursor:'pointer'
  };
  
  const handleSectionToggle = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section.style.display === 'none' || section.style.display === '') {
      section.style.display = 'block'; // Set to open if closed
    } else {
      section.style.display = 'none'; // Set to closed if open
    }
    section.scrollIntoView({ behavior: 'smooth' });
  };

  
  return (
    <div className="electrify-page">
       <div >
        <NavBar/>
        {/*<video autoPlay loop muted controls={false} width="100%" className='video' ref={videoRef}>
      <source src={LightBulbVideo} type="video/mp4" />
      Your browser does not support the video tag.
  </video>*/}
      </div>

      <main>     
       <section>
        <article className='electricb_speech '>
          <div className='electrify_title career' style={myInlineStyle}>Cultivating Tomorrow’s Tech Titans</div>
           <div className='electric_speech'>
           Hamoye Foundation is a non-profit organization dedicated to offering young individuals in developing nations and beyond the chance to acquire marketable skills in technology.  We firmly believe that these skills will be in constant demand for generations to come, and our unwavering commitment is to empower these young talents 
           to achieve their full potential and pave the way for a more prosperous and equitable future for all.
          </div>
          <div className='aelectric_img' style={myInlineStyle2}>
            <img src={aelectrify} className='img_aelectric'/>
          </div>
           

           <div style={{display:'flex', flexDirection:'column', gap:'50px'}}>
          <div>
          <b style={{fontSize:'24px'}}>The Challenge</b>
          <div className='electric_speech'>
          <p>Africa is facing an unprecedented demographic shift.
           By 2050, the continent's youth population is projected to reach 1.2 billion, accounting for nearly half of the world's youth. 
            Some countries in Asia like India, Pakistan, Bangladesh, Philippines and Indonesia have a projection to have a significant and growing youth population by 2050, with a substantial proportion of its population being under the age of 30.
            This explosive growth presents both challenges and opportunities for Africa and Asia.</p>
            <p style={myInlineStyle3}>On the one hand, a large and youthful population can be a driving force for economic growth and development. 
            Young people are more likely to be entrepreneurial and innovative, and they can help to create new jobs and businesses. . Additionally, a young population can contribute to a country's social and cultural vitality.</p>
            <p style={myInlineStyle3}>On the other hand, the explosive growth of the youth population also poses some significant challenges. One of the biggest challenges is 
            creating enough jobs to employ all of the young people entering the workforce. Africa and Asia already have a high unemployment rate, and this is only expected to increase in the coming years.
            By investing in young people in these continents and in similar situations elsewhere around the world, we can create a more prosperous and equitable future for all.</p>
            </div>
           </div>
           <div>
          <b style={{fontSize:'24px'}}>Our Approach</b>
          <div className='electric_speech'>
          <p>The Hamoye Foundation is deeply dedicated to conducting pertinent research that leverages and curates data in developing countries. Our aim is to harness the transformative power of data-driven insights 
          to address critical issues and drive positive change in these regions. Through our research endeavors, we endeavor to gain a comprehensive understanding 
          of the unique challenges, opportunities, and dynamics that are present in developing countries.</p>   
           <p style={myInlineStyle3}>By curating and analyzing data, we seek to unearth valuable insights 
           that can inform evidence-based policies, innovative solutions, and sustainable development strategies.
            Our commitment to this research is driven by the belief that data has the potential to be a catalyst for progress, 
            enabling us to make informed decisions and shape a better future for individuals and communities in developing nations.
           </p>
           <p style={myInlineStyle3}>
           We view data as a tool for empowerment,
            one that can provide a deeper understanding of societal issues, economic trends, 
            and public health challenges. Through our research initiatives, we aspire to shed light on previously 
            overlooked aspects, enabling us to take targeted actions that improve the lives of people in these regions.  
            Ultimately, our dedication to conducting research in developing countries is a testament to our vision of leveraging 
            data to make a positive impact and create a more equitable and prosperous world for all.
           </p>
           <p style={myInlineStyle3}>
           Using these research opportunities and the aforementioned specific skills, we believe graduates of our internship 
           programs will remain in high demand for decades to come. 
            Our programs are free and without encumbrances.  We have developed a unique internship-style learning 
           approach that empowers self-driven participants to attain practical, real-world work experience.  We have made a deliberate choice to concentrate 
           on skill sets that will undeniably remain in high demand for many decades to come.
           </p>
           <p style={myInlineStyle3}>
           Through our initiatives, we aim to empower the next generation of data scientists and professionals to thrive in a 
           world where data-driven decision-making and technological innovation are the driving forces behind progress. 
           We are passionate about ensuring that the pathways to these promising careers are accessible and inclusive for all,
            opening doors to a brighter future for ambitious individuals worldwide.  We provide research opportunities 
            for individuals who pass through our internship programs.
           </p>
              </div>
           </div>
           <div>
          <b style={{fontSize:'24px'}}>Our Motivations</b>
          <div className='electric_speech'>
          <p>As a husband and wife team with roots in Nigeria, 
          we have a profound understanding of the formidable obstacles that confront
           young individuals in emerging economies when it comes to embarking on meaningful career journeys. 
           This intrinsic knowledge and personal experience have fueled our determination to establish the Hamoye Foundation. 
           With the invaluable support of like-minded individuals who share our vision, we have embarked on a mission to empower 
           the youth not only in our homeland but also in various parts of the world.
          </p>
           <p style={myInlineStyle3}>Our unwavering commitment is to provide these ambitious young people,
            regardless of their geographical location, with the means to fully realize their potential and cultivate promising futures. 
            We firmly believe that the Hamoye Foundation can assume a pivotal role in shaping a more prosperous and equitable tomorrow,
             not just for Africa but also for individuals across the globe who are seeking avenues to thrive and contribute
            to a brighter future. Through our collective efforts, 
            we aspire to illuminate the path for countless aspiring young talents and foster a world where opportunities are abundant, and potential knows no boundaries.</p>
           </div>
           </div>
           <div>
          <b style={{fontSize:'24px'}}> Our Impact</b>
          <div className='electric_speech'>
          <p>We take immense pride in the numerous 
          success stories that have emerged from our dynamic initiatives at the Hamoye. These remarkable 
          tales of triumph come from not only the African continent but also extend to regions across Asia
           and beyond. Our virtual internship programs have been instrumental in equipping young individuals
            with invaluable real-world experiences and a diverse range of skills, spanning crucial areas such as
             machine learning, data engineering, MLOps, DevOps, cloud engineering, and the art of data storytelling.
             </p>
             <p style={myInlineStyle3}>
             As we reflect on our journey and the impact we've had, 
             it is truly inspiring to witness these passionate and driven 
             individuals blossoming into accomplished professionals. The Hamoye Foundation's commitment to fostering 
             research and talent and providing access to resources for young minds is a testament to our vision of a 
             world where opportunities are boundless, regardless of geographical boundaries.
             </p>
             <p style={myInlineStyle3}>
             These accomplishments serve as a poignant reminder that talent transcends geographical boundaries, reinforcing our 
             unwavering commitment to nurturing and harnessing the vast potential that resides within the younger generations of Africa, 
             Asia, and other parts of the world. With every success story, 
             we inch closer to the fulfillment of our mission – the establishment of a thriving, worldwide community of adept and empowered individuals prepared to tackle the challenges of tomorrow with unwavering confidence and innovative thinking.
             </p>
             <p style={myInlineStyle3}>
             We are deeply committed to advancing research and offering comprehensive training opportunities that
              foster holistic personal and professional development. Our holistic approach ensures that the impact of
              our programs extends well beyond the acquisition of technical skills. It extends to the transformation of lives and 
              the empowerment of the youth, enabling them to emerge as catalysts for change within their communities and on a global scale.
             </p>
             <p style={myInlineStyle3}>
             By fostering a culture of research and learning, we aim to inspire a 
             new generation of thinkers and problem solvers who are not only adept in 
             their fields but also driven by a desire to make a meaningful impact on society. 
             This comprehensive approach, integrating research, training, and personal growth, 
             reinforces our vision of a brighter future where empowered individuals are equipped to catalyze positive change.
             </p>
             </div>
           </div>
           </div>
          
        </article>
       </section>

       
      </main>

      <Footer/>
    </div>
  );
}

export default OurStory;

